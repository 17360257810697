<script setup>
    import { defineProps, onMounted, computed, ref } from 'vue';
    import axios from 'axios';

    const props = defineProps(['form']);
    const lead = ref({});

    const mapTypeToId = (type) => {
        switch (type) {
            case 'Residencial': return 2;
            case 'comercial': return 3;
            case 'industrial': return 4;
            case 'agro': return 1;
            default: return null; // ou outro valor padrão se necessário
        }
    }

    const fetchCityIdByName = async (cityName) => {
        try {
            const response = await axios.get('https://api.kinsol.com.br/v1/cidades', {
                headers: {
                    'Accept': 'application/json',
                }
            });
            const cities = response.data;
            
            const foundCity = cities.find(city => city.title === cityName);
            return foundCity ? foundCity.id : null;
        } catch (error) {
            console.error('Erro ao buscar o ID da cidade', error);
            return 3065;
        }
    }

    const mapFormToApiPayload = async () => {
        const cidade_id = await fetchCityIdByName(props.form.city);

        return {
            nome: props.form.name, // Mapeamento do campo 'name' para 'nome'
            email: props.form.email,
            telefone: props.form.phoneNumber, // Mapeando 'phoneNumber' para 'telefone'
            tipo_local_id: mapTypeToId(props.form.type), // Valor fixo, ajuste conforme necessário
            pais: "Brasil",
            cidade_id: cidade_id, // Valor fixo, ajuste conforme necessário
            valor_tarifa: props.form.avaregeCost, // Valor fixo, ajuste conforme necessário
            consumo_medio_mensal: props.form.monthlyConsumption, // Valor fixo, ajuste conforme necessário
            proprietario: 1, // Valor fixo, ajuste conforme necessário
            tipo_telhado_id: 2, // Valor fixo, ajuste conforme necessário
            telhado_inclinacao_id: 2, // Valor fixo, ajuste conforme necessário
            telhado_sombreamento_id: 1, // Valor fixo, ajuste conforme necessário
            code: null, // Valor fixo, ajuste conforme necessário
            financiamento: formatPrice(props.form.proposedValue), // Valor fixo, ajuste conforme necessário
            status_lead_id: 1,
            compartilhador_id: props.form.compartilhadorId // Valor fixo, ajuste conforme necessário
        };
    }

    const sendFormDataToAPI = async () => {
        
        try {
            const payload = await mapFormToApiPayload();
            const instance = axios.create();
            instance.defaults.headers.common['X-Requested-With'] = null;
            const newLead = await instance.post('https://api.kinsol.com.br/v1/leads', payload);

            await getLeadData(newLead.data.id);
            await generateProposal(lead.value);

        } catch (error) {
            console.error('Erro ao enviar os dados', error);
            if (error.response && error.response.data) {
                console.error('Detalhes do erro:', error.response.data);
            }
        }
    }

    const generateProposal = async () => {
        try {
            const data = lead.value;

            const instance = axios.create();
            instance.defaults.headers.common['X-Requested-With'] = null;

            await instance.post(`https://api.kinsol.com.br/v1/leads/proposal`, data);

            console.log('Proposta gerada com sucesso');
            
        } catch (error) {
            console.log(error)
        }
    }

    const getLeadData = async (newLead) => {
        try {
            const response = await axios.get(`https://api.kinsol.com.br/v1/leads/${newLead}`);
            lead.value = response.data;

        } catch (error) {
            console.log(error)
        }
    };

    onMounted(() => {
        sendFormDataToAPI();
    });

    // Filters
    const formatPrice = (value) => {
        return value.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL'
        })
    }

    // Methods
    const calculateFioB = (installationType, generation, year, cost) => {
        let injectionFactor = 0.40;

        switch (installationType) {
            case 'commercial':
                injectionFactor = 0.20;
                break;
            case 'Residential':
                injectionFactor = 0.40;
                break;
            case 'industrial':
                injectionFactor = 0.20;
                break;
            case 'agricultural':
                injectionFactor = 0.40;
                break;
        }

        const rate = ((year - 2023) * 4 + 4) / 100;

        return generation * rate * cost * injectionFactor;
    };

    const calculateAnnualSavings = (
        phaseQuantity,
        installationType,
        generation,
        averageConsumption,
        bill,
        cost,
        cip,
        year
    ) => {
        let billWithSystem, savings1Year = 0;

        const fioB = calculateFioB(installationType, generation, year, cost);

        if (phaseQuantity === 1) billWithSystem = cost * 30;
        else if (phaseQuantity === 2) billWithSystem = cost * 50;
        else if (phaseQuantity === 3) billWithSystem = cost * 100;

        let difference = cost * (averageConsumption - generation) - billWithSystem;

        if (difference < 0) difference = 0;

        savings1Year = (bill - cip - difference - fioB - billWithSystem) * 12;

        return savings1Year;
    };

    const calculate25YearSavings = (
        phaseQuantity,
        installationType,
        generation,
        averageConsumption,
        bill,
        cost,
        cip,
        year,
    ) => {
        let sum = 0.0;
        let currentYearBill = 0.0;
        let solarCalculation1Year = 0.0;
        let solarBill1Year = 0.0;

        for (let i = 0; i < 25; i++) {
            let annualSavings = calculateAnnualSavings(phaseQuantity, installationType, generation, averageConsumption, bill, cost, cip, year);
            sum += annualSavings;
            currentYearBill += bill * 12;
            solarCalculation1Year = (bill * 12) - annualSavings;
            solarBill1Year += solarCalculation1Year;

            if (year < 2029) year++;

            cost *= 1.10;
            cip *= 1.10;
            bill *= 1.10;
        }

        return sum;
    };


    // Computed
    const twentyFiveYearSaving = computed(() => {
        return calculate25YearSavings(
            props.form.phaseQtt,
            props.form.Type,
            props.form.generateKWH,
            parseFloat(props.form.monthlyConsumption),
            props.form.currentCount,
            parseFloat(props.form.avaregeCost),
            props.form.cip,
            new Date().getFullYear()
        )
    })

    const oneYearSaving = computed(() => {
        return calculateAnnualSavings(
            props.form.phaseQtt,
            props.form.Type,
            props.form.generateKWH,
            parseFloat(props.form.monthlyConsumption),
            props.form.currentCount,
            parseFloat(props.form.avaregeCost),
            props.form.cip,
            new Date().getFullYear()
        )
    })

    const valueBetween = computed(() => {
        let beforeValue = props.form.proposedValue
        let afterValue = props.form.proposedValue

        beforeValue -= props.form.proposedValue * 0.10
        afterValue += props.form.proposedValue * 0.10

        return `De ${formatPrice(beforeValue)} à ${formatPrice(afterValue)}`
    })

   
    let carbonValue = Math.ceil(0.1244 * 12 * parseFloat(props.form.generateKWH))
    let treeValue = Math.ceil(0.000504 * 12 * parseFloat(props.form.generateKWH))
    let kilometerValue = Math.ceil(0.7097 * parseFloat(props.form.generateKWH))

    let value36x = parseFloat(props.form.proposedValue) * (((Math.pow((1 + 0.022), 36)) * 0.022) / ((Math.pow((1 + 0.022), 36)) - 1))
    var value48x = parseFloat(props.form.proposedValue) * (((Math.pow((1 + 0.022), 48)) * 0.022) / ((Math.pow((1 + 0.022), 48)) - 1))
    var value60x = parseFloat(props.form.proposedValue) * (((Math.pow((1 + 0.022), 60)) * 0.022) / ((Math.pow((1 + 0.022), 60)) - 1))

</script>

<template>
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-10 mx-auto order-md-0 order-1">
                <form>
                <div class="row">
                    <div class="col-md-12">
                        <div class="rounded px-3 py-3">
                            <h2 class="text-25 mb-0 fw-bolder text-info text-uppercase">Resumo de gasto</h2>
                            <hr class="bg-info my-2" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="bg-secondary rounded px-3 py-3">
                            <h3 class="text-15 mb-0 fw-bolder text-primary text-uppercase">com energia solar</h3>
                            <hr class="bg-primary my-2" />
                            <h4 class="text-12 mb-0 fw-bolder text-primary text-uppercase">Conta de energia</h4>
                            <div class="text-dark mb-3">{{ formatPrice(props.form.systemCount) }}</div>
                            <h4 class="text-12 mb-0 fw-bolder text-primary text-uppercase">gasto em 25 anos</h4>
                            <div class="text-dark mb-3">{{ formatPrice(props.form.twentyFiveYearSavingsWithSystem) }}</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="rounded px-3 py-3">
                            <h3 class="text-15 mb-0 fw-bolder text-muted text-uppercase">sem energia solar</h3>
                            <hr class="my-2" />
                            <h4 class="text-12 mb-0 fw-bolder text-dark text-uppercase">Tarifa estimada</h4>
                            <div class="text-muted mb-3"> {{ formatPrice(props.form.avaregeCost) }} kWh</div>
                            <h4 class="text-12 mb-0 fw-bolder text-dark text-uppercase">Conta de energia</h4>
                            <div class="text-muted mb-3"> {{ formatPrice(props.form.currentCount) }}</div>
                            <h4 class="text-12 mb-0 fw-bolder text-dark text-uppercase">gasto em 25 anos</h4>
                            <div class="text-muted mb-3"> {{ formatPrice(props.form.twentyFiveYearSpendWithSystem) }}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="rounded px-3 py-3">
                            <h2 class="text-25 mb-0 fw-bolder text-info text-uppercase">Estimativa de Economia</h2>
                            <hr class="bg-info my-2" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="bg-secondary rounded px-3 py-3">
                            <h3 class="text-15 mb-0 fw-bolder text-primary text-uppercase">Investimento</h3>
                            <hr class="bg-primary my-2" />
                            <h4 class="text-12 mb-0 fw-bolder text-primary text-uppercase">Valor a Pagar</h4>
                            <div class="text-dark mb-3">{{ valueBetween }} <sup>*</sup></div>
                            <h4 class="text-12 mb-0 fw-bolder text-primary text-uppercase">Economia no primeiro ano</h4>
                            <div class="text-dark mb-3"> {{ formatPrice(oneYearSaving) }}</div>
                            <h4 class="text-12 mb-0 fw-bolder text-primary text-uppercase">Economia em 25 anos</h4>
                            <div class="text-dark mb-3">{{ formatPrice(twentyFiveYearSaving) }}</div>
                            <h4 class="text-12 mb-0 fw-bolder text-primary text-uppercase">Payback (retorno de investimento)</h4>
                            <div class="text-dark">{{  props.form.returnInvestiment }} Anos</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="rounded px-3 py-3">
                            <h3 class="text-15 mb-0 fw-bolder text-muted text-uppercase">Sistema Solar</h3>
                            <hr class="my-2" />
                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <h4 class="text-12 mb-0 fw-bolder text-dark text-uppercase">Potência do sistema</h4>
                                    <div class="text-muted mb-3">{{ props.form.modulePotencial }} kWp</div>
                                    <h4 class="text-12 mb-0 fw-bolder text-dark text-uppercase">Ampliação Possível</h4>
                                    <div class="text-muted mb-3">{{ props.form.possibleMagnificationValue }} Placas</div>
                                    <h4 class="text-12 mb-0 fw-bolder text-dark text-uppercase">Geração mensal</h4>
                                    <div class="text-muted mb-3">{{ props.form.generateKWH }} KWh</div>
                                    
                                </div>
                                <div class="col-md-6 col-12">
                                    <h4 class="text-12 mb-0 fw-bolder text-dark text-uppercase">Qtde. de módulos</h4>
                                    <div class="text-muted mb-3">{{ props.form.moduleQtt }} módulos</div>
                                    <h4 class="text-12 mb-0 fw-bolder text-dark text-uppercase">Área necessária</h4>
                                    <div class="text-muted mb-3">{{ props.form.moduleArea }} m²</div>
                                    <h4 class="text-12 mb-0 fw-bolder text-dark text-uppercase">Geração anual</h4>
                                    <div class="text-muted mb-3">{{ props.form.generateKWH * 12 }} KWh</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="rounded px-3 py-3">
                            <h2 class="text-25 mb-0 fw-bolder text-info text-uppercase">Financiamento</h2>
                            <hr class="bg-info my-2" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="bg-secondary rounded px-3 py-3">
                            <div class="row mb-4 align-items-center">
                                <div class="col-md-3 col-3 text-center">
                                    <img src="../../src/assets/images/ic-juros.svg" alt="sem juros" class="img-fluid" width="222" height="229" />
                                </div>
                                <div class="col-md-9 col-9 text-center">
                                    <h4 class="text-primary fw-bolder display-6 mb-0">{{ formatPrice(props.form.proposedValue) }} <sup>*</sup></h4>
                                    <div class="text-primary">à vista</div>
                                </div>
                            </div>
                            <h4 class="text-12 mb-0 fw-bolder text-primary text-uppercase">Em <strong>36</strong> vezes</h4>
                            <div class="text-dark mb-3">36 parcelas de {{ formatPrice(value36x) }} <sup>**</sup></div>
                            <h4 class="text-12 mb-0 fw-bolder text-primary text-uppercase">Em <strong>48</strong>48 vezes</h4>
                            <div class="text-dark mb-3">48 parcelas de {{ formatPrice(value48x) }} <sup>**</sup></div>
                            <h4 class="text-12 mb-0 fw-bolder text-primary text-uppercase">Em <strong>60</strong> vezes</h4>
                            <div class="text-dark mb-3">60 parcelas de {{ formatPrice(value60x) }} <sup>**</sup></div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="rounded px-3 py-3">
                            <h3 class="text-15 mb-0 fw-bolder text-muted text-uppercase">Sustentabilidade</h3>
                            <hr class="my-2" />
                            <h4 class="text-12 mb-0 fw-bolder text-primary text-uppercase">Árvores salvas</h4>
                            <div class="text-dark mb-3">{{ treeValue }} árvores</div>
                            <h4 class="text-12 mb-0 fw-bolder text-primary text-uppercase">Deixará de rodar</h4>
                            <div class="text-dark mb-3">{{ kilometerValue }} Km</div>
                            <h4 class="text-12 mb-0 fw-bolder text-primary text-uppercase">Deixará de emitir CO²</h4>
                            <div class="text-dark mb-3">{{ carbonValue }}  Ton em CO²/ano</div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button class="btn btn-primary border-primary rounded text-15 mx-4 text-uppercase" 
                        ><a href="https://api.whatsapp.com/send?phone=553496721200&text=Olá, fiz uma simulação pela calculadora e gostaria de um orçamento completo." 
                        style="color:white;" target="_blank"
                        >Solicite um orçamento</a>
                    </button>
                    <button @click="$emit('set-page')"
                    class="btn btn-light border-primary text-primary rounded text-15 text-uppercase" 
                    >Refazer simulação</button>
                </div>
                <div class="row py-3">
                    <div class="col-12">
                        <h4 class="text-12 mb-0 fw-bolder text-primary text-uppercase">Importante</h4>
                            <div class="text-muted mt-2 text-10">* É importante ressaltar que os valores obtidos são estimados e baseados em uma simulação do desempenho dos equipamentos sob condições de uso e instalação descritas no Manual do Usuário. Eles devem ser usados apenas como uma referência para tomada de decisões, pois há várias variáveis que podem influenciar no resultado final, como o tipo de telhado, as condições climáticas e as estruturas de fixação. Além disso, o cálculo realizado considera uma média de custo de instalação.</div>
                            <div class="text-muted mt-2 text-10">** Crédito sujeito a aprovação. Taxas e condições podem variar, para mais ou menos, mediante a análise de crédito da instituição financeira. Para mais condições de pagamento, consulte nossos especialistas.</div>
                    </div>
                </div>
                </form>
            </div>
        </div>
    </div>
</template>