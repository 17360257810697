<template>
    <header class="py-5">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-3 col-10 mx-auto mb-2 logotipo">
                    <img width="193" height="148" src="../assets/images/logotipo-calculadora.svg" alt="Kinsol - Calculadora" class="img-fluid" />
                </div>
                <div class="col-md-6 offset-md-1 col-10 mx-auto mt-2">
                    <h1 class="text-uppercase text-info fw-lighter">SEJA <strong class="text-primary">BEM VINDO</strong></h1>
                    <p class="text-muted lead mb-0">Agora você pode descobrir o quanto pode economizar com energia em poucos passos!</p>
                </div>
            </div>
        </div>
    </header>
</template>

<script>

</script>