<script>
</script>

<template>
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-10 mx-auto order-md-0 order-1">
                <div class="row py-4 align-items-center">
                    <div class="col-md-7 mt-4">
                        <img src="../assets/images/ic-slogam-dark.svg" alt="energia solar" class="img-fluid" width="800" height="108" />
                    </div>
                    <div class="col-md-4 col-10 mx-auto">
                        <div @click="$emit('set-page', true)" class="bg-secondary px-4 py-4 text-center rounded">
                            <button class="btn btn-info text-uppercase text-14 px-4 text-white">
                                iniciar minha simulação
                            </button>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center mt-4">
                        <a href = "https://kinsol.com.br/"
                        class="btn btn-outline-secondary btn-lg" target="_blank"> 
                            Conheça a Kinsol
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>

</style>