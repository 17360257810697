<script setup>
    const props = defineProps({
        step: Number,
        currentStep: Number,
        title: String
    })

    const style = {
        iconStep: {
            maxHeight: '64px',
            maxWidth: '64px',
            minWidth: '64px',
            minHeight: '64px',
            marginLeft: '1.5vh',
            marginRight: '1.5vh',
            
        }
    }
</script>

<template>
    <div class="text-center">
        <div v-if="currentStep == step"
            :style="style.iconStep"
            class="rounded-circle lh-sm fw-bold fs-3 pt-3 bg-primary text-white">
            <span>{{ step }}</span>
        </div>
        <div v-else
            :style="style.iconStep"
            class="rounded-circle lh-2 fw-bold fs-5 py-3 bg-secondary text-muted">
            {{ props.step }}
        </div>
        <h2 class="text-12 my-3  text-center text-uppercase text-muted ">
            {{ props.title }}
        </h2>
    </div>
</template>