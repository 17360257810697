<script setup>
import { onBeforeMount, ref } from 'vue'

import Header from './components/Header.vue'
import Home from '../src/views/Home.vue'
import LeadView from '@/views/LeadView.vue';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

const isLeadView = ref(false);

const checkLeadView = () => {
  isLeadView.value = window.location.hash.includes('lead');
};

onBeforeMount(() => {
  checkLeadView();
})



</script>

<template>

  <head>
    <link rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20,400,0,0" />
  </head>
  <Header></Header>
  <div v-if="isLeadView">
    <LeadView></LeadView>
  </div>
  <div v-else>
    <Home></Home>
  </div>

</template>