<script setup>
    import { ref } from 'vue'
    import closeModal from '../assets/js/main.js'
 
    const props = defineProps(['form'])
    const emit = defineEmits(['set-step'])
    let searchFor = ref('')
    let error = ref('')

    const searchByCustomer = () => {
        error.value = ''
        if (/^\d{10,11}$/.test(searchFor.value))
            console.log('telefone')
        else if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(searchFor.value))
            console.log('email')
        else {
            error.value = 'Informações inválida.'
            return
        }

        searchFor.value = ''
        emit('set-step', true)
        closeModal('modalRegister')
    }

</script>

<template>
    <div class="modal fade" id="modalRegister" tabindex="-1" aria-labelledby="modalRegisterLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="modalRegisterLabel">Simulação existente</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>Informe o seu email ou número de telefone</p>
                    <input v-model="searchFor" class="form-control" type="text">
                    <p class="text-danger">{{ error }}</p>
                </div>
                <div class="modal-footer">
                    <button @click="searchByCustomer" type="button" class="btn btn-primary">Concluído</button>
                </div>
            </div>
        </div>
    </div>
</template>