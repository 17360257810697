const closeModal = (elementID) => {
    let modal = document.getElementById(elementID)

    modal.classList.remove('show')
    modal.style.display = 'none'
    modal.setAttribute('aria-hidden', 'true')
    modal.removeAttribute('aria-modal')
    modal.removeAttribute('role')

    let body = document.querySelector('body')
    body.classList.remove('modal-open')
    body.style.removeProperty('overflow')
    body.style.removeProperty('padding-right')

    let backdrop = document.querySelector('.modal-backdrop')
    backdrop.parentNode.removeChild(backdrop)
}

export default closeModal