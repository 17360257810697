<script setup>
   
</script>

<template>
    <footer class="bg-primary py-5 mt-5"
    >
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-3 col-10 mx-auto mb-4 text-center logotipo-r">
                    <img src="../../src/assets/images/logotipo-white.svg" alt="Kinsol" width="150" height="49" class="img-fluid" />
                </div>
                <div class="col-md-4 col-10 mx-auto mb-4">
                    <!--<button data-bs-toggle="modal" data-bs-target="#modalRegister"
                        class="btn btn-light text-primary rounded text-15 w-100 text-uppercase">
                        JÁ FIZ MINHA SIMULAÇÃO ANTES
                    </button>-->
                    
                </div>
                <div class="col-md-4 offset-md-1 col-10 mx-auto text-center mb-4">
                    <img src="../../src/assets/images/ic-futuro-e-agora-white.svg" alt="Futuro é Agora" width="130" height="73" class="img-fluid" />
                </div>
            </div>
        </div>
        <div class="position-fixed p-2 d-none d-md-block" style="bottom: 20px; right: 22px;">
          <a href = "https://api.whatsapp.com/send?phone=553496721200&text=Olá, quero mais detalhes sobre energia solar, vim através da calculadora!" 
            class="" target="_blank">
            <img src="../assets/images/svg/whatsapp-icon.svg" style="height:  45px; width:  45px; max-width: ; max-height: fit-content;"> 
          </a>
        </div>
        <div class="position-fixed p-2 d-block d-md-none" style="bottom: 20px; right: 22px;">
          <a href = "https://api.whatsapp.com/send?phone=553496721200&text=Olá, quero mais detalhes sobre energia solar, vim através da calculadora!" 
            class="" target="_blank">
            <img src="../assets/images/svg/whatsapp-icon.svg" style="height:  6vh; width:  6vh;"> 
          </a>
        </div>
    </footer>
    
</template>