<template>
    <div class="col-md-12 m-6 p-6">
        <div class="row" v-if="isAuthenticated">
            <div v-if="isLoading">
                <DotAnimation></DotAnimation>
            </div>
            <div v-if="!lead && !isLoading">
                <div class="d-flex justify-content-center">
                    <h1 class="text-primary">OOOOOPS...</h1>
                </div>
                <div class="d-flex justify-content-center">
                    <h1 class="text-info">Lead não encontrado!</h1>
                </div>
            </div>
            <div v-if="lead" class="container col-md-10">
                <div class="row d-flex justify-content-center">
                    <div class="card col-md-12" style="border: none!important">
                        <div class="card-body col-md-12">
                            <div class="row d-flex justify-content-end mb-2">
                                <div class="col-md-2 d-flex justify-content-end">
                                    <button @click="generateProposal" class="btn btn-outline-info">
                                        Gerar Proposta
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-12 d-flex justify-content-center">
                                    <h1 class="text-info">{{ lead.nome }}</h1>
                                </div>
                            </div>

                            <div class="row d-flex justify-content-between my-4">
                                <div class="col-md-4 my-3">
                                    <div class=" d-flex justify-content-center">
                                        <span class="text-primary">
                                            DADOS PESSOAIS
                                        </span>

                                    </div>
                                    <div>
                                        <hr class="text-info my-2">
                                    </div>
                                    <div class="mb-2">
                                        <span class="text-info d-flex justify-content-center my-2">
                                            {{ lead.nome ?? '' }}
                                        </span>
                                        <span class="text-info d-flex justify-content-center my-2">
                                            {{ lead.email ?? '' }}
                                        </span>
                                        <span class="text-info d-flex justify-content-center my-2">
                                            {{ lead.telefone ?? '' }}
                                        </span>
                                        <span class="text-info d-flex justify-content-center my-2">
                                            {{ lead.proprietario ? 'Proprietário' : 'Não-proprietário' }}
                                        </span>
                                    </div>

                                </div>
                                <div class="col-md-4 my-3">
                                    <div class=" d-flex justify-content-center">
                                        <span class="text-primary">
                                            LOCAL
                                        </span>
                                    </div>
                                    <div>
                                        <hr class="text-info my-2">
                                    </div>
                                    <div class="mb-2">
                                        <span class="text-info d-flex justify-content-center my-2">
                                            {{ lead.cidade.title }} - {{ lead.cidade.state.title }}
                                        </span>
                                        <span class="text-info d-flex justify-content-center my-2">
                                            {{ lead.tipo_local.nome }}
                                        </span>
                                        <span class="text-info d-flex justify-content-center my-2">
                                            Telhado {{ lead.telhado_inclinacao.nome }}, {{
            lead.telhado_sombreamento.nome }} - {{ lead.tipo_telhado.nome }}
                                        </span>
                                        <span class="text-info d-flex justify-content-center my-2">
                                            {{ lead.consumo_medio_mensal }} (kWh)
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-4 my-3">
                                    <div class=" d-flex justify-content-center">
                                        <span class="text-primary">
                                            PROPOSTA
                                        </span>
                                    </div>
                                    <div>
                                        <hr class="text-info my-2">
                                    </div>
                                    <div class="mb-2">
                                        <span class="text-info d-flex justify-content-center my-2">
                                            {{ validateProposalFinancing(lead.financiamento) }}
                                        </span>
                                        <span class="text-info d-flex justify-content-center my-2">
                                            {{ lead.valor_tarifa }} (Tarifa)
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="d-flex justify-content-center">
                <h2 class="text-info">
                    Digite o e-mail do lead
                </h2>
            </div>
            <div class="d-flex justify-content-center">
                <div class="col-md-4">
                    <input type="text" v-model="email" class="form form-control" style="">
                </div>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <small class="text-danger" v-if="validateEmail.checked">{{ validateEmail.message }}</small>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <div class="col-md-1">
                    <button @click="authenticateLeadView" class="btn btn-primary mx-4">
                        Acessar
                    </button>
                </div>
            </div>

        </div>
    </div>

</template>

<script setup>
import { onBeforeMount, ref } from 'vue'
import axios from 'axios';
import DotAnimation from '../components/DotAnimation.vue'
import 'bootstrap/dist/css/bootstrap.css'

const lead = ref(null);
const isLoading = ref(false);
const isAuthenticated = ref(null);
const email = ref('');
const validateEmail = ref({
    checked: false,
    message: ''
});

const getLeadId = () => {
    return window.location.hash.split("/")[2];
};

const getLeadData = async () => {
    isLoading.value = true;
    try {
        const leadId = getLeadId();
        const response = await axios.get(`https://api.kinsol.com.br/v1/leads/${leadId}`);
        lead.value = response.data;
        isLoading.value = false;

    } catch (error) {
        console.log(error)
        isLoading.value = false;
    }
};

const authenticateLeadView = async () => {
    const leadId = getLeadId();

    const data = {
        id: leadId,
        email: email.value
    };

    await axios.get(`https://api.kinsol.com.br/v1/leads/authenticate/${data.id}/${data.email}`)
        .then((response) => {
            isAuthenticated.value = response.data;

            if (isAuthenticated.value === false) {
                validateEmail.value.checked = true;
                validateEmail.value.message = 'E-mail inválido';
            }

        })
        .catch((error) => {
            validateEmail.value.checked = true;
            validateEmail.value.message = 'Erro de autenticação';
            console.log(error)
        });
}

const generateProposal = async () => {
    try {
        const data = lead.value;

        const instance = axios.create();
        instance.defaults.headers.common['X-Requested-With'] = null;

        await instance.post(`https://api.kinsol.com.br/v1/leads/proposal`, data);

    } catch (error) {
        console.log(error)
    }
}


const validateProposalFinancing = (value) => {
    if (value.includes('NaN'))
        return 'Sem valor definido'

    return value
};


onBeforeMount(() => {
    getLeadData();
})

</script>
