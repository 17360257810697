<script setup>
    import { ref, onBeforeMount } from 'vue'
    import { vMaska } from 'maska'
    import axios from 'axios'

    const props = defineProps(['form'])
    const emit = defineEmits(['set-zipcode'])
    let cep = ref('')
    let messageError = ref({
        name: '',
        phoneNumber: '',
        email: '',
        cep: '',
        type: '',
    })

    const handleZipCode = async () => {
        if (cep.value.replace('-','').length !== 8) {
            messageError.value.cep = 'CEP inválido, verifique os dígitos.'
            return
        }
        
        const url = `https://viacep.com.br/ws/${cep.value.replace('-','')}/json/`
        let response = await axios.get(url)

        if (response.status !== 200 || response.data.hasOwnProperty('erro')) {
            messageError.value.cep = 'CEP não localizado.'
            return
        }
        
        messageError.value.cep = ''
        emit('set-zipcode', response.data)
    }
    const handleEmailVerify = () => {
        let verify = /@kinsol\.com$/
    }
    const handleNextPage = () => {
        messageError.value = {name: '', phoneNumber: '', email: '', cep: '', type: '',}

        handleEmailVerify()
        if (!props.form.name)
            messageError.value.name = '*Infome um nome válido.'
        if (props.form.phoneNumber.length !== 15)
            messageError.value.phoneNumber = '*Infome um número de telefone válido.'
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(props.form.email))
            messageError.value.email = '*Infome um email válido.'
        if (!props.form.type)
            messageError.value.type = '*Necessário informar um tipo.'

        if (!props.form.name)
            return document.getElementById('name').focus()
        else if (props.form.phoneNumber.length !== 15)
            return document.getElementById('telefone').focus()
        else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(props.form.email))
         return document.getElementById('email').focus()
        else if (!(props.form.cep || props.form.city || props.form.state))
            return document.getElementById('cep').focus()
        else if (!props.form.type)
            return document.getElementById('img1').focus()

        emit('set-page', true)
    }
    const validateFields = () => {
        
    }

    onBeforeMount(() => {
        cep.value = props.form.zipCode
    })

</script>

<template>
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-10 mx-auto order-md-0 order-1">
                <div class="row">
                    <div class="col-md-6">

                        <div class="bg-secondary rounded px-3 py-3">
                            <h2 class="text-15 mb-0 fw-bolder text-info text-uppercase">Dados Pessoais</h2>
                            <hr class="bg-info my-2" />

                            <label class="text-primary text-12 ms-3 text-uppercase mb-1 d-block fw-bold">Nome
                                Completo</label>
                            <input v-model="props.form.name" id="name" type="text" name="NomeCompleto"
                                class="form-control text-12 mb-1 bg-white " required />
                            <p class="m-0 text-danger">{{ messageError.name }}</p>

                            <label class="text-primary text-12 ms-3 text-uppercase mb-1 d-block fw-bold">Telefone /
                                WhatsApp</label>
                            <input id="telefone" v-model="props.form.phoneNumber" v-maska data-maska="(##) #####-####" type="text" name="Telefone"
                                class="form-control text-12 mb-1 bg-white " required />
                            <p class="m-0 text-danger">{{ messageError.phoneNumber }}</p>
                            
                            <label class="text-primary text-12 ms-3 text-uppercase mb-1 d-block fw-bold">seu melhor
                                E-Mail</label>
                            <input id="email" v-model="props.form.email" type="email" name="Email" class="form-control text-12 mb-1 bg-white "
                                required />
                            <p class="m-0 text-danger">{{ messageError.email }}</p>
                        </div>

                    </div>
                    <div class="col-md-6">
                        <div class="py-3 px-3">
                            <h2 class="text-15 mb-0 fw-bolder text-info text-uppercase">Local onde será instalado
                            </h2>
                            <hr class="bg-info my-2" />
                            <label class="text-primary text-12 ms-3 text-uppercase mb-1 d-block fw-bold">
                                CEP *
                            </label>
                            <div class="input-group mb-1">
                                <input id="cep" v-model="cep" v-maska data-maska="#####-###" name="CEP" type="text" class="form-control text-12 cep">
                                <button @click="handleZipCode" class="btn text-12 btn-outline-secondary" type="button" id="button-addon2">Buscar</button>
                            </div>

                            <p class="m-0 text-danger">{{ messageError.cep }}</p>
                            
                            <label class="text-primary text-12 ms-3 text-uppercase mb-1 d-block fw-bold">
                                Cidade *
                            </label>
                            <input v-model="props.form.city" name="Cidade" disabled
                                class="form-control text-12 mb-2 bg-secondary ">

                            <label class="text-primary text-12 ms-3 text-uppercase mb-1 d-block fw-bold">
                                Estado *
                            </label>
                            <input v-model="props.form.state" name="Estado" disabled
                                class="form-control text-12 mb-2 bg-secondary ">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="py-3 px-3">
                            <h2 class="text-15 mb-0 fw-bolder text-info text-uppercase">Qual o tipo de Imóvel</h2>
                            <hr class="bg-info my-2" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="py-3 px-3">
                            <div class="row">
                                <div class='col-md-3 radio-md col-6 text-center'>
                                    <input v-model="props.form.type" type="radio" name="tipoSolucao[]" id="img1" class="d-none imgbgchk"
                                        value="Residencial" />
                                    <label for="img1" class="text-center">
                                        <img src=".././assets/images/ic-solucoes-residencial.svg"
                                            alt="Residencial">
                                        <div class="tick_container">
                                            <div class="tick"><img
                                                    src="../../src/assets/images/ic-check-white.svg" alt="check"
                                                    class="img-fluid" width="20" height="20" /></div>
                                        </div>
                                        <strong
                                            class="text-primary text-12 text-center text-uppercase mb-1 d-block fw-bold">Residencial</strong>
                                    </label>
                                </div>
                                <div class='col-md-3 radio-md col-6 text-center'>
                                    <input v-model="props.form.type" type="radio" name="tipoSolucao[]" id="img2" class="d-none imgbgchk"
                                        value="comercial" />
                                    <label for="img2" class="text-center">
                                        <img src=".././assets/images/ic-solucoes-comercial.svg"
                                            alt="Residencial">
                                        <div class="tick_container">
                                            <div class="tick"><img
                                                    src="../../src/assets/images/ic-check-white.svg" alt="check"
                                                    class="img-fluid" width="20" height="20" /></div>
                                        </div>
                                        <strong
                                            class="text-primary text-12 text-center text-uppercase mb-1 d-block fw-bold">comercial</strong>
                                    </label>
                                </div>
                                <div class='col-md-3 radio-md col-6 text-center'>
                                    <input v-model="props.form.type" type="radio" name="tipoSolucao[]" id="img3" class="d-none imgbgchk"
                                        value="industrial" />
                                    <label for="img3" class="text-center">
                                        <img src=".././assets/images/ic-solucoes-industrial.svg"
                                            alt="Residencial">
                                        <div class="tick_container">
                                            <div class="tick"><img
                                                    src="../../src/assets/images/ic-check-white.svg" alt="check"
                                                    class="img-fluid" width="20" height="20" /></div>
                                        </div>
                                        <strong
                                            class="text-primary text-12 text-center text-uppercase mb-1 d-block fw-bold">industrial</strong>
                                    </label>
                                </div>
                                <div class='col-md-3 radio-md col-6 text-center'>
                                    <input v-model="props.form.type" type="radio" name="tipoSolucao[]" id="img4" class="d-none imgbgchk"
                                        value="agro" />
                                    <label for="img4" class="text-center">
                                        <img src=".././assets/images/ic-solucoes-agro.svg    " alt="Residencial">
                                        <div class="tick_container">
                                            <div class="tick"><img
                                                    src="../../src/assets/images/ic-check-white.svg" alt="check"
                                                    class="img-fluid" width="20" height="20" /></div>
                                        </div>
                                        <strong
                                            class="text-primary text-12 text-center text-uppercase mb-1 d-block fw-bold">agro</strong>
                                    </label>
                                </div>
                            </div>
                            <p class="m-0 text-danger">{{ messageError.type }}</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="py-3 px-3">
                            <hr />
                            <div class="row">
                                <div class="col-6">
                                    <button
                                        @click="$emit('set-page', false)"
                                        class="btn btn-outline-secondary px-4 mb-0 text-12 text-uppercase">
                                        <span class="material-symbols-outlined text-10">arrow_back_ios</span> voltar
                                    </button>
                                </div>
                                <div class="col-6 text-end">
                                    <button
                                        @click="handleNextPage"
                                        class="btn btn-primary px-4 mb-0 text-12 text-uppercase">
                                        avançar  <span class="material-symbols-outlined text-10">arrow_forward_ios</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>