<script setup>
    import { BaseTransitionPropsValidators, ref } from 'vue'
    import { vMaska } from "maska"
    const emit = defineEmits(['perfom-calculation'])
    const props = defineProps(['form'])

    let showMessageError = ref({
        monthlyConsumption: false,
        avaregeCost: false
    })

    const handleNextPage = () => {
        if (props.form.monthlyConsumption < 100) {
            showMessageError.value.monthlyConsumption = true
            document.getElementById('consumo-medio').focus()
            return
        } else if (props.form.avaregeCost <= 0 && !props.form.rateEnabled) {
            showMessageError.value.avaregeCost = true
            document.getElementById('tarifa').focus()
            return
        } else {
            props.form.avaregeCost = setAverageCostByProperty(props.form.type);
        }

        emit('perfom-calculation', true)
    }

    const setAverageCostByProperty = (propertyType) => {
        if(propertyType === 'Residencial')
            return 0.72
        if(propertyType === 'comercial')
            return 0.78
        if(propertyType === 'industrial')
            return 0.85
        if(propertyType === 'agro')
            return 0.75

        return 0.74;
    };

    // Quando o campo perder foco,
    // o mesmo será formatado para ter duas casas decimais.
    const blurMonthlyConsumption = () => {
        props.form.monthlyConsumption = parseFloat(props.form.monthlyConsumption.toFixed(2))
    }

</script>

<template>
    <div class="container">
            <div class="row">
                <div class="col-md-8 col-10 mx-auto order-md-0 order-1">
                    <div class="row pt-5">
                        <div class="col-md-10 mx-auto">
                            <div class="bg-secondary rounded px-3 py-3 mb-2">
                                <div class="row align-items-center">
                                    <div class="col-md-2 col-2 text-center"><img src="../../src/assets/images/ic-consumo-2.svg" class="img-fluid" width="50" height="50" alt="consumo" /></div>
                                    <div class="col-md-7 col-10">
                                        <label class="text-primary text-12 text-uppercase mb-1 d-block fw-bold">Quanto de energia (kWh) você está consumindo em média?</label>
                                        <div class="input-group">
                                            <input
                                                v-model.number="props.form.monthlyConsumption"
                                                type="number"
                                                class="form-control bg-white money"
                                                name="ConsumoMedio"
                                                id="consumo-medio"
                                                @blur="blurMonthlyConsumption">
                                            <div class="input-group-text">kWh</div>
                                        </div>
                                        <small v-if="!showMessageError.monthlyConsumption">Você deve inserir um valor maior que R$100,00</small>
                                        <small  class="text-danger" v-else>Você deve inserir um valor maior que 100Kwh</small>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="bg-secondary rounded px-3 py-3 mb-2">
                                <div class="row align-items-center">
                                    <div class="col-md-2 col-2 text-center"><img src="../../src/assets/images/ic-consumo-1.svg" class="img-fluid" width="50" height="50" alt="consumo" /></div>
                                    <div class="col-md-10 col-10">
                                        <label class="text-primary text-12 text-uppercase mb-1 d-block fw-bold">Quanto você está pagando de tarifa (R$/kWh)?</label>
                                        <div class="row align-items-center">
                                            <div class="col-auto mb-2">
                                                <div class="input-group">
                                                    <div class="input-group-text">R$</div>
                                                    <input
                                                        :disabled="props.form.rateEnabled"
                                                        v-model="props.form.avaregeCost"
                                                        v-maska data-maska="9 99#.##" data-maska-tokens="9:[0-9]:repeated" data-maska-reversed
                                                        type="text" class="form-control bg-white money"
                                                        id="tarifa"
                                                        name="Tarifa">
                                                </div>
                                                <small class="text-danger" v-if="showMessageError.avaregeCost">Valor da tarifa inválido</small>
                                            </div>
                                            <div class="col">
                                                <div class="form-check">
                                                    <input v-model="props.form.rateEnabled" class="form-check-input" type="checkbox" name="NaoSeiTarifa" :value="true">
                                                    <label class="form-check-label text-12 mt-1 text-uppercase">
                                                      Não Sei
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-secondary rounded px-3 py-3 mb-2">
                                <div class="row align-items-center">
                                    <div class="col-md-2 col-2 text-center"><img src="../../src/assets/images/ic-consumo-3.svg" class="img-fluid" width="50" height="50" alt="consumo" /></div>
                                    <div class="col-md-10 col-10">
                                        <label class="text-primary text-12 text-uppercase mb-1 d-block fw-bold">Você é o proprietário da residência?</label>
                                        <div class="form-check form-check-inline">
                                            <input  v-model="props.form.owner" class="form-check-input" type="radio" name="inlineRadioOptions" :value="true">
                                            <label class="form-check-label text-uppercase text-12 mt-1">sim</label>
                                          </div>
                                          <div class="form-check form-check-inline">
                                            <input v-model="props.form.owner" class="form-check-input" type="radio" name="inlineRadioOptions" :value="false">
                                            <label class="form-check-label text-uppercase text-12 mt-1">não</label>
                                          </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-secondary rounded px-3 py-3">
                                <!-- se login ou e-mail for de funcionario -->
                                <div class="row align-items-center">
                                    <div class="col-md-12">
                                        <label for="formFile" class="text-primary text-12 text-uppercase mb-1 d-block fw-bold">Enviar conta de Luz atualizada</label>
                                        <input class="form-control" type="file" id="formFile" name="FileConta" value="">
                                    </div>
                                </div>
                                <!-- fim se login ou e-mail for de funcionario -->
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-10 mx-auto">
                            <div class="py-3 px-3">
                                <hr class="my-2" />
                                <div class="row">
                                    <div class="col-6">
                                        <button
                                            @click="$emit('set-page', false)"
                                            class="btn btn-outline-secondary px-4 mb-0 text-12 text-uppercase">
                                            <span class="material-symbols-outlined text-10">arrow_back_ios</span> voltar
                                        </button>
                                    </div>
                                    <div class="col-6 text-end">
                                        <button
                                            @click="handleNextPage"
                                            class="btn btn-primary px-4 mb-0 text-12 text-uppercase">
                                            avançar  <span class="material-symbols-outlined text-10">arrow_forward_ios</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>